<template>
  <div>
    
    <div class="row">
      <div class="col-3 my-3">
        <label for=""> Matricule </label>
        <input type="text" v-model="employee.code" class="form-control" />
      </div>
      <div class="col-3 my-3">
        <label for="">Nom</label>
        <input type="text" v-model="employee.lastName" class="form-control" />
      </div>
      <div class="col-3 my-3">
        <label for="">Prénom</label>
        <input type="text" v-model="employee.firstName" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for="">Téléphone</label>
        <input type="text" v-model="employee.phone" class="form-control" />
      </div>
      <div class="col-3 my-3">
        <label for="">Email</label>
        <input type="email" v-model="employee.email" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for="">CIN</label>
        <input type="text" v-model="employee.cin" class="form-control" />
      </div>

      
      <div class="col-3 my-3">
        <label for=""> CNSS </label>
        <input
          type="text"
          v-model="employee.socialSecurityNumber"
          class="form-control"
        />
      </div>
      
      
      <div class="col-3 my-3">
        <label for="">Date de Naissance</label>
        <input
          type="date"
          v-model="employee.dateOfBirth"
          class="form-control"
        />
      </div>

      <div class="col-3 my-3">
        <label for="">Situation Familiale</label>
        <select v-model="employee.maritalStatus" class="form-select">
          <option value="Marié">Marié</option>
          <option value="Divorcé">Divorcé</option>
          <option value="Célibataire ">Célibataire</option>
        </select>
      </div>
      <div class="col-3 my-3">
        <label for="">Ville</label>
        <input type="text" v-model="employee.city" class="form-control" />
      </div>
      <div class="col-3 my-3">
        <label for="">Adresse</label>
        <input type="text" v-model="employee.address" class="form-control" />
      </div>
      <div class="col-3 my-3">
        <label for="">Nombre d'Enfants</label>
        <input
          type="number"
          v-model="employee.numberOfChildren"
          class="form-control"
        />
      </div>

      <div class="col-3 my-3">
        <label for="">Date d'Embauche</label>
        <input
          type="datetime-local"
          v-model="employee.hireDate"
          class="form-control"
        />
      </div>
      
      <div class="col-3 my-3">
        <label for=""> Badge </label>
        <select v-model="employee.card_reference" class="form-select">
          <option value="">Sélectionnez un badge </option>
          <option
            v-for="(card, index) in cards"
            :key="index++"
            :value="card.reference"
          >
            {{ card.badgeNumber }}
          </option>
        </select>
      </div>
      <div class="col-3 my-3">
        <label for="">Intitulé du Poste</label>
        <input type="text" v-model="employee.jobTitle" class="form-control" />
      </div>


    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(employee)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      employee: {},
    };
  },
  computed: {},
  methods: {
    async save(data) {
      await this.$store.dispatch("employee/store", data);
    },
    async empty() {
      this.$store.commit("employee/setEmployee", {});
    },
  },
  beforeMount() {
    this.$store.commit("employee/setEmployee", {});
  },
};
</script>
